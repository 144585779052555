/*
Copyright 2020, Michel Heusser
ALl rights reserved
https://github.com/michheusser
*/

// **************************** ACTIONS ****************************
export const closeAbout = () => {
  // Closing About Pane
  return {
    type: "CLOSE_ABOUT",
    payload: {},
  };
};
